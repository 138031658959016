$(document).on("turbolinks:load", () => {
  let passwordValidator = require("password-validator")
  let schema = new passwordValidator()
  schema.is().min(8).has().uppercase().has().lowercase().has().digits().has().symbols()

  $("#country").on("change", (event) => {
    const $element = $(event.target)
    $("#state").prop("disabled", $element.val() !== "US")
  })

  $(".strong-pw").hide()
  $(".valid-password-check-mark").hide()

  $("#password").on("keyup", (event) => {
    const requirements = ["min","uppercase","lowercase","digits","symbols"]
    const $element = $(event.target)
    const invalidComponents = schema.validate($element.val(), {list: true})

    requirements.forEach((requirement) => {
      if (invalidComponents.includes(requirement)) {
        $element.closest(".row").find(`.password-validation div.${requirement}`).css("color", "red")
        $element.closest(".row").find(`.password-validation div.${requirement} span i.valid-password-check-mark`).hide()
      } else {
        $element.closest(".row").find(`.password-validation div.${requirement}`).css("color", "green")
        $element.closest(".row").find(`.password-validation div.${requirement} span i.valid-password-check-mark`).show()
      }
    }) 

    if (invalidComponents.length < 1) {
      $element.closest(".row").find(`.password-validation div`).css("color", "green")
      $(".strong-pw").show()
      $(".strong-pw span i.valid-password-check-mark").show()
    } else {
      $(".strong-pw").hide()
      $(".strong-pw span i.valid-password-check-mark").hide()
    }
  })

  $("#password_confirmation").on("keyup", (event) => {
    const $element = $(event.target)
    const $statusText = $element.closest(".row").find(".password-validation")
    if ($element.val() === $("#password").val()) {
      $(".confirm-password").hide()
    } else {
      $statusText.html("Must match password")
      $statusText.show()
    }
  })

  $("#change-email").on("click", (event) => {
    const $element = $(event.target)
    $($element.data("target")).modal("show")
    return false
  })

  $("#change-phone").on("click", (event) => {
    const $element = $(event.target)
    $($element.data("target")).modal("show")
    return false
  })
})


if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', () => {
    check_captcha_pw_fields()
    check_if_captcha_present()
  }, {
    once: true
  });
}

function check_captcha_pw_fields() {
  const pw = $('input#password').val()
  const pwConf = $('input#password_confirmation').val()
  if($('div.g-recaptcha').length){
    if (!pw) {
      $('.password-validation.invalid-feedback').show()
    } else if (pw == pwConf) {
      $('.password-validation.invalid-feedback').hide()
    } else {
      $('.password-validation.invalid-feedback').show()
    }
  } else if (pw == pwConf && pw) {
    $('.password-validation.invalid-feedback').hide()
  } 
}

function check_if_captcha_present() {
  const alertMsg = $('.card.registration-card-shadow .alert-danger')
  const recaptchaShow = $('div.g-recaptcha')
  if ((!alertMsg.length) && (recaptchaShow.length)) {
    scroll_to_if_present(recaptchaShow)
  }
}

function scroll_to_if_present(element) {
  $(element)[0].scrollIntoView({
    behavior: 'smooth', // or "auto" or "instant"
    block: 'start' // or "end"
  });
}